import { mask } from "../helper";

export interface LivechatLocale {
  heading: string;
  description: string;
}

export class LivechatSetting {
  token: string;
  clientId: string;
  locales: {
    [key: string]: LivechatLocale;
  };
  urls: string[];
  themeColor?: string;

  constructor(params: {
    token?: string;
    clientId: string;
    locales: {
      [key: string]: LivechatLocale;
    };
    urls?: string[];
    themeColor?: string;
  }) {
    this.clientId = params.clientId;
    this.token = params.token || mask(this.clientId);
    this.locales = params.locales || {};
    this.urls = params.urls || [];
    this.themeColor = params.themeColor || "#38509c";
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}
