import { evaluate } from "mathjs";
import { HTTP_CONTENT_TYPE, HTTP_HEADERS, HTTP_METHOD } from "../api";
import { Logger, to, unique } from "../helper";
import {
  Language,
  REGEX_DOUBLE_SPACE,
  REGEX_EMAIL,
  REGEX_NUMBER,
  REGEX_PARAMETER,
  REGEX_PHONE,
  REGEX_QUESTION_NO,
  REGEX_URL,
  Timezone,
  Translations,
} from "../misc";
import {
  Channel,
  Customer,
  FORMATTABLE_CHANNELS,
  FRIDAY_ID,
  Message,
  MessageFlags,
  MessageParams,
  QuickReply,
  QuickReplyType,
  QUICKREPLY_CHANNELS,
} from "./conversation";
import { bold, italic } from "./format";
import { Attachment } from "./response";

const axios = require("axios").default;
const FormData = require("form-data");
const uuid = require("uuid/v1");
const moment = require("moment-timezone");
const DECIMAL_PLACE = 2;

export const ChatformControl = {
  Undo: ["undo", "back", "回上題", "上題"],
  Skip: ["skip", "next", "略過", "下題"],
  Result: ["result", "結果"],
  Exit: ["exit", "stop", "end", "cancel", "停止", "離開", "取消"],
  Restart: ["restart", "重新開始", "重新"],
  True: [
    "true",
    "yes",
    "confirm",
    "ok",
    "okay",
    "agree",
    "是",
    "確認",
    "確定",
    "同意",
  ],
  False: ["false", "no", "not", "不是", "否", "不"],
  EoF: "eof",
};

export enum FormStyle {
  Poll = "poll",
  Form = "form",
}

export enum ChatformQuestionType {
  Input = "input",
  Number = "number",
  Email = "email",
  Phone = "phone",
  ToF = "tof",
  Confirmation = "confirmation",
  Radio = "radio",
  Textarea = "textarea",
  Checkbox = "checkbox",
  Select = "select",
  Upload = "upload",

  Section = "section",
  Page = "page",
  Response = "response",
}

export const QUESTION_TYPE_WITH_OPTIONS = [
  ChatformQuestionType.Checkbox,
  ChatformQuestionType.ToF,
  ChatformQuestionType.Radio,
  ChatformQuestionType.Confirmation,
  ChatformQuestionType.Select,
];

export const tofOptions = [
  {
    text: {
      [Language.en]: Translations[Language.en].chatform.yes,
      [Language.zh]: Translations[Language.zh].chatform.yes,
    },
    value: "true",
  },
  {
    text: {
      [Language.en]: Translations[Language.en].chatform.no,
      [Language.zh]: Translations[Language.zh].chatform.no,
    },
    value: "false",
  },
];

export const confirmationOptions = [
  {
    text: {
      [Language.en]: Translations[Language.en].chatform.confirm,
      [Language.zh]: Translations[Language.zh].chatform.confirm,
    },
    value: "confirm",
  },
  {
    text: {
      [Language.en]: Translations[Language.en].chatform.undo,
      [Language.zh]: Translations[Language.zh].chatform.undo,
    },
    value: "undo",
  },
  {
    text: {
      [Language.en]: Translations[Language.en].chatform.cancel,
      [Language.zh]: Translations[Language.zh].chatform.cancel,
    },
    value: "cancel",
  },
];

export interface ChatformPayload {
  answerId: string;
  chatform: Chatform;
  language: Language;
  answers: { [key: string]: any };
  questionIds: string[];
  eof?: boolean;
  next?: string[];
}

export interface TextLocale {
  [key: string]: string;
}

export class Chatform {
  chatformId?: string;
  clientId: string;
  title: string;
  defaultLanguage: Language;
  languages: Language[];
  description?: TextLocale;
  questions: ChatformQuestion[];
  ending?: ChatformResponse;
  _createdAt: number;
  _updatedAt: number;
  active: boolean;
  showControl: boolean;
  spreadsheetId?: string;

  constructor(params: {
    chatformId?: string;
    clientId: string;
    title?: string;
    defaultLanguage: Language;
    languages?: Language[];
    description?: TextLocale;
    questions?: ChatformQuestion[];
    ending?: ChatformResponse;
    _createdAt?: number;
    _updatedAt?: number;
    active?: boolean;
    showControl?: boolean;
    spreadsheetId?: string;
  }) {
    this.clientId = params.clientId;
    this.defaultLanguage = params.defaultLanguage;
    this.languages = params.languages || [params.defaultLanguage];
    this.chatformId = params.chatformId || null;
    this.title = params.title || "";
    this._createdAt = params._createdAt || new Date().valueOf();
    this._updatedAt = params._updatedAt || new Date().valueOf();
    this.questions = params.questions
      ? params.questions.map((q) => new ChatformQuestion(q))
      : [];
    this.description = params.description || {};
    this.active = !!params.active;
    this.showControl = !!params.showControl;
    if (params.spreadsheetId) this.spreadsheetId = params.spreadsheetId;
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export class ChatformQuestion {
  _id: string;
  // order: number;
  questionType: ChatformQuestionType;
  value?: any;
  key?: string;
  text: TextLocale;
  attachments?: { [key: string]: Attachment[] };
  icon?: string;
  description?: TextLocale;
  placeholder?: TextLocale;
  required?: boolean;
  // disabled?: boolean;
  // readonly?: boolean;
  type?: string;
  next?: string;
  options?: { text?: TextLocale; value: string; next?: string }[];
  response?: ChatformResponse;
  validation?: {
    min?: number;
    max?: number;
    operator?: string;
    value?: string | number | boolean;
  };
  valid?: boolean;

  constructor(params: {
    _id?: string;
    questionType?: ChatformQuestionType;
    value?: any;
    key?: string;
    text?: TextLocale;
    attachments?: { [key: string]: Attachment[] };
    icon?: string;
    placeholder?: TextLocale;
    description?: TextLocale;
    required?: boolean;
    next?: string;
    options?: { text?: TextLocale; value: string; next?: string }[];
    response?: ChatformResponse;
    validation?: {
      min?: number;
      max?: number;
      operator?: string;
      value?: string | number | boolean;
    };
  }) {
    this._id = params._id || uuid();
    this.questionType = params.questionType || ChatformQuestionType.Input;
    if (params.next) this.next = params.next;
    this.text = params.text || {};
    this.attachments = params.attachments || {};
    if (params.icon) this.icon = params.icon;
    if (params.description) this.description = params.description;
    if (params.placeholder) this.placeholder = params.placeholder;
    if (params.value) this.value = params.value;
    if (params.key) this.key = params.key;
    this.required = !!params.required;
    if (params.options) this.options = params.options;
    if (this.questionType === ChatformQuestionType.Response) {
      this.response = new ChatformResponse(params.response || {});
    }
    if (params.validation) this.validation = params.validation;
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export class ChatformAnswerEntry {
  answerId: string;
  questionId: string;
  customerId: string;
  value: string | number | boolean;
  timestamp: number;
  channel: Channel;

  constructor(params: {
    answerId: string;
    questionId: string;
    customerId: string;
    value: string | number | boolean;
    channel: Channel;
    timestamp?: number;
  }) {
    this.answerId = params.answerId;
    this.customerId = params.customerId;
    this.questionId = params.questionId;
    this.value = params.value;
    this.channel = params.channel;
    this.timestamp = params.timestamp || new Date().valueOf();
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export class ChatformAnswer {
  clientId: string;
  chatformId: string;
  answers: ChatformAnswerEntry[];

  constructor(params: {
    clientId: string;
    chatformId: string;
    answers: ChatformAnswerEntry[];
  }) {
    this.clientId = params.clientId;
    this.chatformId = params.chatformId;
    this.answers = params.answers
      ? params.answers.map((a) => new ChatformAnswerEntry(a))
      : [];
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export const API_RESPONSE_PARAMS = ["params", "body", "headers"];

export enum ChatformResponseType {
  Calculation = "calculation",
  API = "api",
  Message = "message",
}

export interface ChatformApiResponse {
  url: string;
  method: HTTP_METHOD;
  params?: { [key: string]: string };
  body?: { [key: string]: string };
  contentType?: HTTP_CONTENT_TYPE;
  headers?: { [key: string]: string };
}

export class ChatformResponse {
  responseType: ChatformResponseType;
  formula?: string;
  api?: ChatformApiResponse;

  constructor(params: {
    responseType?: ChatformResponseType;
    // text?: TextLocale;
    formula?: string;
    api?: ChatformApiResponse;
  }) {
    this.responseType = params.responseType || ChatformResponseType.Message;
    if (this.responseType === ChatformResponseType.Calculation) {
      this.formula = params.formula || "";
    }
    if (this.responseType === ChatformResponseType.API) {
      this.api = params.api || {
        url: "",
        method: HTTP_METHOD.GET,
        contentType: HTTP_CONTENT_TYPE.JSON,
      };
    }
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export const getAnswerFromKey = (
  key: string,
  questions: ChatformQuestion[],
  answers: { [key: string]: string | boolean | number }
) => {
  if (!key) return null;
  const fields = key.split(".");
  const question = questions.find((q) => q.key === fields[0]);
  if (!question) return null;
  if (!answers[question._id]) return null;
  switch (fields.length) {
    case 1:
      return answers[question._id] || null;
    case 2:
      return answers[question._id][fields[1]] || null;
    case 3:
      return answers[question._id][fields[1]][fields[2]] || null;
    case 4:
      return answers[question._id][fields[1]][fields[2]][fields[3]] || null;
    case 5:
      return (
        answers[question._id][fields[1]][fields[2]][fields[3]][fields[4]] ||
        null
      );
    default:
      return null;
  }
};

export const getParameters = (text: string): string[] => {
  if (!text) return null;
  const match = text.match(REGEX_PARAMETER);
  if (match) return match.map((m) => m.replace(/[\{\}]/g, "").trim());
  return null;
};

export const keyValid = (question: ChatformQuestion): boolean => {
  if (
    question.questionType === ChatformQuestionType.Response &&
    question.response.responseType !== ChatformResponseType.Message
  ) {
    if (!question.key) return false;
  }

  if (question.key) {
    const match = question.key.match(/^[A-Za-z0-9_]+$/g);
    if (!match) return false;
    if (match.length > 1) return false;
  }
  return true;
};

const findOptionFromText = (
  text: string,
  options: any[],
  languages: Language[]
) => {
  if (!options || options.length === 0) return null;
  text = text.replace(REGEX_QUESTION_NO, "").trim();
  let option = options.find((option) => {
    let found = false;
    if (option.value) {
      const v = option.value.toLowerCase().trim();
      if (v === text) return true;
    }

    for (let language of languages) {
      if (option.text[language]) {
        const t = option.text[language].toLowerCase().trim();
        if (t === text) {
          found = true;
          break;
        }
      }
    }
    return found;
  });

  if (!option) {
    const i = parseInt(text);
    if (isNaN(i)) return null;
    if (i > options.length || i < 1) return null;
    return options[i - 1];
  }
  return option;
};

const textToAnswer = (
  text: string,
  originalText: string,
  question: ChatformQuestion,
  languages: Language[]
) => {
  let regexMatch: string[];
  let answerOption: any = null;
  let answer: string | boolean | number = null;
  // validate answer value
  switch (question.questionType) {
    case ChatformQuestionType.Radio:
      answerOption = findOptionFromText(text, question.options, languages);
      answer = answerOption && answerOption.value ? answerOption.value : null;
      break;
    case ChatformQuestionType.Select:
      answerOption = findOptionFromText(text, question.options, languages);
      answer = answerOption && answerOption.value ? answerOption.value : null;
      break;
    case ChatformQuestionType.Checkbox:
      let ans = text.match(/[0-9]+/g);
      if (ans) {
        ans = unique(ans);
        // console.log(ans);
        let ansArr = [];
        let valid = true;
        for (let a of ans) {
          const o = findOptionFromText(a, question.options, languages);
          const v = o && o.value ? o.value : null;
          v ? ansArr.push(v) : (valid = false);
        }
        if (valid) {
          answer = String(ansArr.reduce((acc, cur) => (acc += `${cur},`), ""));
          answer = answer.substring(0, answer.length - 1);
          // console.log(answer);
        }
      }
      break;
    case ChatformQuestionType.ToF:
      answerOption = findOptionFromText(text, question.options, languages);
      answer =
        answerOption && answerOption.value
          ? answerOption.value === "true"
            ? true
            : false
          : null;
      break;
    case ChatformQuestionType.Confirmation:
      answerOption = findOptionFromText(text, question.options, languages);
      answer =
        ChatformControl.True.includes(text) ||
        (answerOption && answerOption.value && answerOption.value === "confirm")
          ? true
          : false;
      break;
    case ChatformQuestionType.Phone:
      regexMatch = text.match(REGEX_PHONE);
      if (regexMatch && regexMatch.length === 1) answer = regexMatch[0];
      break;
    case ChatformQuestionType.Number:
      regexMatch = text.match(REGEX_NUMBER);
      if (regexMatch && regexMatch.length === 1) {
        const n = parseFloat(regexMatch[0]);
        answer = isNaN(n) ? null : n;
      }
      break;
    case ChatformQuestionType.Email:
      regexMatch = text.match(REGEX_EMAIL);
      if (regexMatch && regexMatch.length === 1) answer = regexMatch[0];
      break;
    case ChatformQuestionType.Upload:
      regexMatch = text.match(REGEX_URL);
      if (regexMatch && regexMatch.length === 1) answer = regexMatch[0];
      break;
    default:
      answer = originalText;
  }
  // console.log(regexMatch);
  return { answer, answerOption };
};

export const apiParamValid = (
  api: ChatformApiResponse,
  variables: string[]
): boolean => {
  for (let field of API_RESPONSE_PARAMS) {
    if (!api[field]) continue;
    const keys = Object.keys(api[field]);
    if (keys.length > 0) {
      for (let k of keys) {
        if (field === "headers" && !HTTP_HEADERS.includes(k.toLowerCase()))
          return false;
        api[field][k] = api[field][k].trim();
        const v = getParameters(api[field][k]);
        // console.log(v);
        if (!v) continue;
        if (v && (v.length !== 1 || v[0].length + 4 !== api[field][k].length))
          return false;
        if (!variables.includes(v[0].split(".")[0])) return false;
      }
    }
  }
  return true;
};

export const formulaValid = (formula: string, variables: string[]): boolean => {
  if (!formula) return false;
  formula = formula.trim();
  const params = getParameters(formula);
  if (params) {
    if (!params.every((p) => variables.includes(p.split(".")[0]))) return false;
    formula = formula.replace(REGEX_PARAMETER, "1");
  }
  try {
    const result = evaluate(formula);
    if (isNaN(result)) return false;
  } catch (e) {
    return false;
  }
  return true;
};

export const substituteVariables = (
  text: string,
  questions: ChatformQuestion[],
  answers: any,
  format: boolean
) => {
  const params = getParameters(text);
  if (!params) return text;
  // console.log(variables);
  params.forEach((p) => {
    // console.log(p);
    let sub = "";
    const ans = getAnswerFromKey(p, questions, answers);
    if (ans) {
      sub = String(ans);
      if (sub.includes(".")) {
        const n = parseFloat(sub);
        const d = sub.split(".")[1];
        sub = isNaN(n)
          ? sub
          : d.length > DECIMAL_PLACE
          ? n.toFixed(DECIMAL_PLACE)
          : sub;
      }
      sub = format ? bold(sub) + " " : sub;
    }
    // console.log(key, i);
    text = text.replace(p, sub);
  });
  text = text
    .replace(/[\{\}]/g, "")
    .replace(REGEX_DOUBLE_SPACE, " ")
    .trim();
  // console.log(text);
  return text;
};

export const quickReplyToText = (
  text: string,
  quickReplies: QuickReply[],
  format: boolean
): string => {
  text +=
    "\n" +
    quickReplies.reduce(
      (acc, cur, i) =>
        acc +
        (format ? `${bold(String(i + 1))})` : `${i + 1})`) +
        ` ${cur.title || cur.payload} \n`,
      ""
    );
  return text.trim();
};

export const constructHttpParams = (
  api: ChatformApiResponse,
  questions: ChatformQuestion[],
  answers: { [key: string]: string | boolean | number }
): { params?: any; headers?: any; body?: any } => {
  let variables = [];
  questions.forEach((q) => {
    if (q.key) variables.push(q.key);
  });
  if (!apiParamValid(api, variables)) return null;

  let request = {
    params: {},
    body: {},
    headers: {},
  };
  for (let field of API_RESPONSE_PARAMS) {
    let keys = Object.keys(api[field]);
    if (keys.length > 0) {
      for (let k of keys) {
        const v = getParameters(api[field][k]);
        if (!v) {
          request[field][k] = api[field][k];
          continue;
        }
        const ans = String(getAnswerFromKey(v[0], questions, answers));
        if (!ans) return null;
        request[field][k] = ans;
      }
    }
  }

  switch (api.contentType) {
    case HTTP_CONTENT_TYPE.FORM:
      const data = new FormData();
      for (let key of Object.keys(request.body)) {
        data.append(key, request.body[key]);
      }
      request.body = data;
      break;
    case HTTP_CONTENT_TYPE.URLENCODED:
      request.headers["Content-Type"] = "application/x-www-form-urlencoded";
      request.body = new URLSearchParams(request.body).toString();
      break;
    default:
      request.headers["Content-Type"] = "application/json";
  }

  // console.log(request);
  return Object.keys(request).length > 0 ? request : null;
};

export const getResponseResult = async (
  question: ChatformQuestion,
  questions: ChatformQuestion[],
  payload: ChatformPayload
) => {
  const logger = new Logger("getResponseResult");
  switch (question.response.responseType) {
    case ChatformResponseType.Calculation:
      if (question.response.formula) {
        let scope = {};
        Object.keys(payload.answers).forEach((_id) => {
          const q = questions.find((q) => q._id === _id);
          if (q && q.key) scope[q.key] = payload.answers[_id];
        });

        const formula = question.response.formula
          .replace(/{{/g, "(")
          .replace(/}}/g, ")");
        logger.info(`Calculating ${formula}`);
        logger.info(scope);
        try {
          // const result = math.evaluate(question.response.formula, scope);
          const result = evaluate(formula, scope);
          // console.log(result);
          if (!isNaN(result)) return parseFloat(result.toFixed(5));
        } catch (e) {
          return null;
        }
      }
      break;
    case ChatformResponseType.API:
      if (question.response.api) {
        const request = constructHttpParams(
          question.response.api,
          questions,
          payload.answers
        );
        if (!request) return null;
        const [err, res] = await to(
          axios({
            method: question.response.api.method,
            url: question.response.api.url,
            data: request.body,
            headers: request.headers,
            params: request.params,
            timeout: 30000,
          })
        );
        if (err) return logger.error(err.response.status, null);
        const result = res as any;
        if (result.status !== 200) return null;
        logger.success(result.data);
        return result.data || null;
      }
  }
  return null;
};

export const chatformQuestionToMessage = async (
  questionId: string,
  payload: ChatformPayload,
  channel: Channel,
  verify = false,
  required = false,
  confirm = false
) => {
  const chatform = payload.chatform;
  const language = payload.language;
  const questions = chatform.questions;
  const format = FORMATTABLE_CHANNELS.includes(channel);
  const questionIndex = questions.findIndex((q) => q._id === questionId);
  const question = questions[questionIndex];
  const instructionSeparator = "\n";

  if (
    question.questionType === ChatformQuestionType.Response &&
    (questionIndex === questions.length - 1 ||
      question.next === ChatformControl.EoF)
  )
    payload.eof = true;

  if (
    question.questionType !== ChatformQuestionType.Response &&
    !payload.questionIds.includes(question._id)
  )
    payload.questionIds.push(question._id);

  let text =
    question.text[language] || question.text[chatform.defaultLanguage] || "";

  // TODO customize instruction?
  const instruction: string = confirm
    ? Translations[language].chatform.confirmation
    : verify
    ? Translations[language].chatform.verify
    : required
    ? Translations[language].chatform.required
    : null;

  if (instruction) {
    text = format
      ? `${italic(instruction)}${instructionSeparator}${bold(text)}`
      : `${instruction}${instructionSeparator}${text}`;
  }

  // TODO customize instruction?
  let questionInstruction: string = null;
  if (
    QUESTION_TYPE_WITH_OPTIONS.includes(question.questionType) &&
    !QUICKREPLY_CHANNELS.includes(channel)
  ) {
    questionInstruction = Translations[language].chatform.option_instruction;
  } else {
    switch (question.questionType) {
      case ChatformQuestionType.Number:
        questionInstruction =
          Translations[language].chatform.number_instruction;
        break;
      case ChatformQuestionType.Checkbox:
        questionInstruction =
          Translations[language].chatform.checkbox_instruction;
        break;
    }
  }

  if (questionInstruction)
    text += format
      ? instructionSeparator + italic(`[${questionInstruction}]`)
      : `${instructionSeparator}[${questionInstruction}]`;

  if (question.questionType === ChatformQuestionType.Response) {
    // console.log(answers);
    const responseResult = await getResponseResult(
      question,
      questions,
      payload
    );
    if (responseResult) payload.answers[question._id] = responseResult;
    // console.log(responseResult);
  }

  let params: MessageParams = {
    senderId: FRIDAY_ID,
    text: substituteVariables(
      text,
      chatform.questions,
      payload.answers,
      format
    ),
    flag: MessageFlags.Chatform,
    payload,
    channel,
    quickReplies: [],
  };

  if (
    question.attachments &&
    question.attachments[language] &&
    question.attachments[language].length > 0
  ) {
    params.attachments = question.attachments[language];
  }

  if (question.options && question.options.length > 0) {
    params.quickReplies = [
      ...params.quickReplies,
      ...question.options.map((option) => {
        let tmp: any = {
          type: QuickReplyType.Text,
          title: option.text[language] || option.value,
        };

        tmp.payload = option.value || option.text[language];
        return tmp as QuickReply;
      }),
    ];
  }

  if (channel === Channel.Facebook) {
    switch (question.questionType) {
      case ChatformQuestionType.Email:
        params.quickReplies = [
          {
            type: QuickReplyType.Email,
          },
        ];
        break;
      case ChatformQuestionType.Phone:
        params.quickReplies = [
          {
            type: QuickReplyType.Phone,
          },
        ];
        break;
    }
  }

  if (params.quickReplies.length === 0) {
    delete params.quickReplies;
  } else if (
    !QUICKREPLY_CHANNELS.includes(channel) ||
    question.questionType === ChatformQuestionType.Checkbox
  ) {
    params.text = quickReplyToText(params.text, params.quickReplies, format);
    if (question.questionType === ChatformQuestionType.Checkbox) {
      delete params.quickReplies;
    }
  }

  if (
    question.questionType !== ChatformQuestionType.Response &&
    !params.quickReplies
  ) {
    params.quickReplies = [];
    if (!question.required)
      params.quickReplies.push({
        type: QuickReplyType.Text,
        title: Translations[language].chatform.skip,
        payload: Translations[language].chatform.skip,
      });

    params.quickReplies.push({
      type: QuickReplyType.Text,
      title: Translations[language].chatform.undo,
      payload: Translations[language].chatform.undo,
    });

    params.quickReplies.push({
      type: QuickReplyType.Text,
      title: Translations[language].chatform.cancel,
      payload: Translations[language].chatform.cancel,
    });
  }

  return new Message(params);
};

export const nextQuestions = async (
  channel: Channel,
  payload: ChatformPayload,
  currentIndex: number,
  nextQuestionIndex?: number
): Promise<Message[]> => {
  nextQuestionIndex = nextQuestionIndex || currentIndex + 1;
  if (nextQuestionIndex >= payload.chatform.questions.length) return [];
  let returnMessages: Message[] = [];
  let n = 0;
  do {
    let nextMessage = await chatformQuestionToMessage(
      payload.chatform.questions[nextQuestionIndex]._id,
      payload,
      channel
    );
    nextMessage.timestamp += n;
    returnMessages.push(nextMessage);
    currentIndex = nextQuestionIndex;
    nextQuestionIndex++;
    n++;
    payload = nextMessage.payload;
  } while (
    nextQuestionIndex < payload.chatform.questions.length &&
    payload.chatform.questions[nextQuestionIndex - 1].questionType ===
      ChatformQuestionType.Response &&
    payload.chatform.questions[nextQuestionIndex - 1].next !==
      ChatformControl.EoF
  );
  return returnMessages;
};

export const chatformToMessage = async (
  channel: Channel,
  chatform: Chatform,
  language?: Language,
  next?: string[]
): Promise<Message[]> => {
  let messages: Message[] = [];
  const format = FORMATTABLE_CHANNELS.includes(channel);

  if (!language) language = chatform.defaultLanguage;
  let payload: ChatformPayload = {
    answerId: uuid(),
    chatform,
    language,
    answers: {},
    questionIds: [],
  };
  if (next && next.length > 0) payload.next = next;

  if (chatform.description) {
    const text =
      chatform.description[language] ||
      chatform.description[chatform.defaultLanguage];
    if (text && text !== "") {
      messages.push(
        new Message({
          senderId: FRIDAY_ID,
          text: text,
          payload,
          channel,
          flag: MessageFlags.Chatform,
        })
      );
    }
  }

  if (chatform.showControl) {
    messages.push(
      new Message({
        senderId: FRIDAY_ID,
        text: format
          ? italic(Translations[language].chatform.controls)
          : Translations[language].chatform.controls,
        payload,
        flag: MessageFlags.Chatform,
        channel,
      })
    );
  }

  if (chatform.questions.length > 0) {
    const m = await chatformQuestionToMessage(
      chatform.questions[0]._id,
      payload,
      channel
    );
    if (m) {
      messages.push(m);
      if (
        chatform.questions[0].questionType === ChatformQuestionType.Response
      ) {
        messages = [...messages, ...(await nextQuestions(channel, payload, 0))];
      }
    }
  }

  return messages;
};

export const handleChatformControl = async (
  payload: ChatformPayload,
  text: string,
  currentIndex: number,
  channel: Channel
): Promise<Message[]> => {
  const logger = new Logger("handleChatformControl");
  const chatform = payload.chatform as Chatform;
  const language = payload.language as Language;
  const question = chatform.questions[currentIndex];
  const currentQuestionId = question._id;

  if (ChatformControl.Undo.includes(text)) {
    logger.info("Undo");
    if (payload.questionIds.length > 1) {
      payload.questionIds.pop();
    }

    return [
      await chatformQuestionToMessage(
        payload.questionIds[payload.questionIds.length - 1],
        payload,
        channel
      ),
    ];
  } else if (ChatformControl.Exit.includes(text)) {
    logger.info("Exit");
    payload.eof = true;
    return [
      new Message({
        senderId: FRIDAY_ID,
        flag: MessageFlags.Chatform,
        channel,
        payload,
      }),
    ];
  } else if (ChatformControl.Skip.includes(text)) {
    logger.info("Skip");
    if (question.required) {
      return [
        await chatformQuestionToMessage(
          currentQuestionId,
          payload,
          channel,
          false,
          true
        ),
      ];
    } else {
      payload.answers[question._id] = "-";
      return nextQuestions(channel, payload, currentIndex);
    }
  } else if (ChatformControl.Restart.includes(text)) {
    logger.info("Restart");
    payload.questionIds = [chatform.questions[0]._id];
    payload.answers = {};
    const first = await chatformQuestionToMessage(
      chatform.questions[0]._id,
      payload,
      channel
    );
    return chatform.questions[0].questionType === ChatformQuestionType.Response
      ? [...[first], ...(await nextQuestions(channel, payload, 0))]
      : [first];
  }
  return null;
};

export const handleChatformAnswer = async (
  payload: ChatformPayload,
  text: string,
  channel: Channel
): Promise<Message[]> => {
  const logger = new Logger("handleChatformAnswer");
  text = text.trim();
  const originalText = text;

  const chatform = payload.chatform as Chatform;
  const language = payload.language as Language;
  const defaultLanguage = chatform.defaultLanguage;
  let currentQuestionId: string;
  let currentIndex: number;

  if (payload.questionIds && payload.questionIds.length > 0) {
    currentQuestionId = payload.questionIds[payload.questionIds.length - 1];
    currentIndex = chatform.questions.findIndex(
      (q) => q._id === currentQuestionId
    );
  } else {
    currentIndex = 0;
    currentQuestionId = chatform.questions[0]._id;
    payload.questionIds = [currentQuestionId];
  }
  const question = chatform.questions[currentIndex];
  // logger.info(
  //   `Answering: ${
  //   question.text[language] || question.text[defaultLanguage]
  //   } => ${text}`
  // );

  text = text.toLowerCase();

  // handle chatform controls
  const controlMessages = await handleChatformControl(
    payload,
    text,
    currentIndex,
    channel
  );
  if (controlMessages) return controlMessages;

  const answerKey = question._id;
  const { answer, answerOption } = textToAnswer(
    text,
    originalText,
    question,
    chatform.languages
  );

  if (answer === null) {
    logger.warn("Invalid answer");
    return [
      await chatformQuestionToMessage(
        currentQuestionId,
        payload,
        channel,
        true,
        false,
        question.questionType === ChatformQuestionType.Confirmation
      ),
    ];
  }

  // handle chatform controls
  const confirmControlMessages = await handleChatformControl(
    payload,
    String(answer),
    currentIndex,
    channel
  );
  if (confirmControlMessages) return confirmControlMessages;

  logger.success(
    `Answered: ${
      question.text[language] || question.text[defaultLanguage]
    } => ${answer}`
  );
  payload.answers[answerKey] = answer;

  let nextQuestionIndex: number;
  if (answerOption && answerOption.next && answerOption.next !== "") {
    if (answerOption.next === ChatformControl.EoF) {
      nextQuestionIndex = chatform.questions.length;
    } else {
      const i = chatform.questions.findIndex(
        (q) => q._id === answerOption.next
      );
      nextQuestionIndex = i !== -1 ? i : currentIndex + 1;
    }
  } else if (question.next && question.next !== "") {
    if (question.next === ChatformControl.EoF) {
      nextQuestionIndex = chatform.questions.length;
    } else {
      const i = chatform.questions.findIndex((q) => q._id === question.next);
      nextQuestionIndex = i !== -1 ? i : currentIndex + 1;
    }
  } else {
    nextQuestionIndex = currentIndex + 1;
  }

  if (nextQuestionIndex < chatform.questions.length) {
    return nextQuestions(channel, payload, currentIndex, nextQuestionIndex);
  } else {
    payload.eof = true;
    return [
      new Message({
        senderId: FRIDAY_ID,
        flag: MessageFlags.Chatform,
        payload,
        channel,
      }),
    ];
  }
};

export const submissionTime = (
  timestamp: number,
  timezone: Timezone | string
): string => {
  try {
    timezone =
      timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      Timezone.HK;
  } catch (e) {
    timezone = Timezone.HK;
  }
  return moment.tz(timestamp, timezone).format("YYYY/MM/DD HH:mm");
};

const filterQuestionForAnswer = (
  questions: ChatformQuestion[]
): ChatformQuestion[] => {
  return questions.filter(
    (q) =>
      ![
        ChatformQuestionType.Response,
        ChatformQuestionType.Page,
        ChatformQuestionType.Section,
      ].includes(q.questionType) ||
      (q.questionType === ChatformQuestionType.Response && q.response && q.key)
  );
};

export const toChatformHeaders = (
  chatform: Chatform,
  lang: Language
): string[] => {
  return [
    ...[
      Translations[lang].general.channel,
      Translations[lang].general.contact,
      Translations[lang].general.name,
    ],
    ...filterQuestionForAnswer(chatform.questions).map(
      (q, i) => q.key || q.text[lang] || `Q.${i + 1}`
    ),
    ...[Translations[lang].general.submission_time],
  ];
};

export const formatChatformResponseDataEntry = (
  chatform: Chatform,
  answers: ChatformAnswerEntry[],
  customer: Customer
): any[] => {
  if (answers.length === 0) return null;
  const questionIds = filterQuestionForAnswer(chatform.questions).map(
    (q) => q._id
  );
  const channel = answers[0].channel || "-";
  let row: any[] = [String(channel)];
  row.push(customer ? customer.email || customer.phoneNumber || "-" : "-");
  row.push(
    customer
      ? customer.name || customer.firstName || customer.lastName || "-"
      : "-"
  );
  row = row.map((r) => r.trim().replace(/^[+=]/, ""));
  questionIds.forEach((questionId, i) => {
    const ans = answers.find((a) => a.questionId === questionId);
    row.push(ans ? ans.value : "");
  });
  row.push(answers[0].timestamp);
  // console.log(row);
  return row;
};

export const formatChatformResponseData = (
  chatform: Chatform,
  chatformAnswer: ChatformAnswer,
  customers: Customer[],
  timezone = Timezone.HK
): any[][] => {
  const lang = chatform.defaultLanguage;
  const header = toChatformHeaders(chatform, lang);

  const answerEntries = unique(chatformAnswer.answers.map((a) => a.answerId));
  let data = [];
  answerEntries.forEach((answerId) => {
    const answers = chatformAnswer.answers.filter(
      (a) => a.answerId === answerId
    );
    if (answers.length > 0) {
      const customer = customers.find(
        (c) => c.customerId === answers[0].customerId
      );
      const row = formatChatformResponseDataEntry(chatform, answers, customer);
      if (row) data.push(row);
    }
  });
  const lastColumn = header.length - 1;
  data = data.sort((a, b) => a[lastColumn] - b[lastColumn]);
  data.forEach((d) => {
    d[lastColumn] = submissionTime(d[lastColumn], timezone);
  });
  return [...[header], ...data];
};
