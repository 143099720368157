import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  Attachment,
  AttachmentType,
  Channel,
  Message,
  MessageFlags,
  QuickReplyType,
} from "@models/messenger";
import { REGEX_BOLD, REGEX_ITALIC } from "@models/misc";
import { Task } from "@models/task";
import { customerIdFromConversationId, formatDate, formatTime } from "@utility";
import "firebase/firestore";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-message-item",
  templateUrl: "./message-item.component.html",
  styleUrls: ["./message-item.component.scss"],
})
export class MessageItemComponent implements OnInit {
  @Input() message: Message;
  @Input() index: number;
  @Input() conversationId: string;
  @Input() channel: Channel;
  @Input() previousTimestamp: number;
  @Input() me: string;
  @Input() last: boolean;
  @Input() first: boolean;
  @Output() reply = new EventEmitter();

  AttachmentType: typeof AttachmentType = AttachmentType;
  MessageFlags: typeof MessageFlags = MessageFlags;
  QuickReplyType: typeof QuickReplyType = QuickReplyType;
  task: Task;
  timeString: string;
  ready = false;
  html: SafeHtml = null;
  knowledgeQuery: string;
  showDate = false;
  date: string;
  FRIDAY_ID = environment.FRIDAY_ID;
  customerId: string;

  constructor(private sanitization: DomSanitizer) {}

  ngOnInit() {
    this.customerId = customerIdFromConversationId(this.conversationId);
    this.timeString = this.parseTimeString();
    const currentDate = new Date(this.message.timestamp) || new Date();
    const previousDate =
      this.previousTimestamp > 0
        ? new Date(this.previousTimestamp)
        : new Date();
    this.showDate = this.first
      ? true
      : currentDate.getDate() !== previousDate.getDate();
    this.date = formatDate(currentDate);
    this.html = this.formatMessage();
  }

  formatMessage() {
    if (!this.message.text) return null;
    let html = this.message.text;
    let formatted = false;
    const italic = html.match(REGEX_ITALIC);
    if (italic) {
      italic.forEach(
        (i) =>
          (html = html.replace(i, `<i>${i.substring(1, i.length - 1)}</i>`))
      );
      formatted = true;
    }
    const bold = html.match(REGEX_BOLD);
    if (bold) {
      bold.forEach(
        (b) =>
          (html = html.replace(b, `<b>${b.substring(1, b.length - 1)}</b>`))
      );
      formatted = true;
    }
    return formatted ? this.sanitization.bypassSecurityTrustHtml(html) : null;
  }

  parseTimeString() {
    if (!this.message.timestamp) return null;
    return formatTime(this.message.timestamp);
  }

  toFile(attachment: Attachment) {
    return this.sanitization.bypassSecurityTrustUrl(
      `data:${attachment.mimetype};base64,${attachment.data}`
    );
  }

  respondManually() {
    if (this.message.flag === MessageFlags.UnknownError) return true;
    if (this.message.flag === MessageFlags.UnknownLanguage) return true;
    return false;
  }

  quickReply(text: string) {
    this.reply.emit(text);
  }

  attachmentTracker(index: number, attachment: Attachment) {
    return attachment.url
      ? `${index.toString()}-${attachment.url}`
      : `${index.toString()}-${attachment.id}`;
  }
}
