<div class="chatroom column" *ngIf="origin && conversation" [@smooth]>
  <div class="chatroom-header column">
    <div class="title">
      {{
        locale && locale.heading
          ? locale.heading
          : ("livechat.default-heading" | translate)
      }}
    </div>
    <div class="info">
      {{
        locale && locale.description
          ? locale.description
          : ("livechat.default-description" | translate)
      }}
    </div>

    <button class="icon-button close" (click)="closeChatroom()">
      <i class="material-icons">clear</i>
    </button>

    <button
      class="icon-button language"
      mat-button
      [matMenuTriggerFor]="languageMenu"
    >
      <i class="material-icons">language</i>
    </button>
  </div>

  <!-- Contact Input-->
  <ng-container
    *ngIf="!conversation.customer.email && !conversation.customer.phoneNumber"
  >
    <div class="chatroom__content">
      <div class="contact">
        <div class="wrapper">
          <input
            [(ngModel)]="contact"
            type="text"
            placeholder="{{ 'livechat.enter-contact' | translate }}"
            (keydown.enter)="updateContact()"
          />
          <div class="error-message" *ngIf="contactError" translate>
            livechat.contact-error
          </div>

          <button class="text-button" (click)="updateContact()">
            <span translate>livechat.start-chatting</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Contact Input-->

  <!-- Chatroom -->
  <ng-container
    *ngIf="conversation.customer.email || conversation.customer.phoneNumber"
  >
    <div id="chatroom" class="chatroom__content" #chatroom>
      <app-empty
        *ngIf="conversation.messages.length == 0"
        [icon]="'inbox'"
        [message]="'inbox.no-message'"
      >
      </app-empty>

      <app-message-item
        *ngFor="
          let message of conversation.messages;
          let i = index;
          trackBy: messageTracker
        "
        [message]="message"
        [previousTimestamp]="i > 0 ? conversation.messages[i - 1].timestamp : 0"
        [index]="i"
        [conversationId]="conversation.conversationId"
        [channel]="conversation.channel"
        [me]="conversation.me"
        [last]="i === conversation.messages.length - 1"
        [first]="i === 0"
        (reply)="quickReply($event)"
        [@smooth]
      >
      </app-message-item>

      <div class="end-indicator" *ngIf="conversation.messages.length !== 0">
        <span translate>general.latest</span>
      </div>
    </div>

    <div class="chatroom__input">
      <div class="samples" *ngIf="uploads.length > 0 && !sending">
        <div
          class="samples__item"
          *ngFor="let attachment of uploads; let i = index"
        >
          <a class="name">{{ attachment.name }}</a>
          <a class="error" (click)="deleteFile(i)">
            <i class="material-icons">close</i>
          </a>
        </div>
      </div>

      <div class="input">
        <textarea
          [(ngModel)]="messageText"
          type="text"
          placeholder="{{ 'placeholder.type-messages' | translate }}"
          (keydown.enter)="sendMessage()"
          [disabled]="sending"
        ></textarea>

        <div class="actions">
          <app-emoji-picker
            [disabled]="sending || uploading"
            [primary]="true"
            [icon]="true"
            (emoji)="insertEmoji($event)"
          >
          </app-emoji-picker>

          <app-loader-button
            icon="attach_file"
            [disabled]="sending || uploading"
            (click)="uploadFile()"
            [loading]="uploading"
            [primary]="true"
          >
          </app-loader-button>

          <app-loader-button
            icon="send"
            [disabled]="
              sending || uploading || (!messageText && uploads.length == 0)
            "
            (click)="sendMessage()"
            [loading]="sending"
            [primary]="true"
          >
          </app-loader-button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Chatroom -->
</div>

<input #upload type="file" (change)="onFileChange($event)" multiple />

<app-loader *ngIf="!conversation" [size]="4"></app-loader>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
  <button
    class="language-options"
    *ngFor="let language of languages"
    mat-menu-item
    (click)="setLanguage(language)"
  >
    {{ "general." + language | translate }}
  </button>
</mat-menu>
