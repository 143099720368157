<ng-container
  *ngIf="message && !message.pending && (message.text || message.attachments)"
>
  <div class="message" [class.right]="message.senderId === customerId">
    <div class="message__date" *ngIf="showDate">
      {{ date }}
    </div>

    <div class="message__wrapper">
      <!-- Message -->
      <div class="message__text" *ngIf="message.text">
        <span *ngIf="!html">{{ message.text }} </span>
        <span *ngIf="html" [innerHTML]="html"> </span>
        <span class="no-select"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        <span class="no-select" *ngIf="message.senderId === customerId"
          >&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        <div class="message__timestamp">
          <ng-container *ngIf="timeString">{{ timeString }}</ng-container>
          <ng-container *ngIf="message.senderId === customerId">
            <i class="material-icons" *ngIf="!message.delivered">done</i>
            <i class="material-icons" *ngIf="message.delivered">done_all</i>
          </ng-container>
        </div>
      </div>
      <!-- Message -->

      <!-- Attachments -->
      <div class="message__attachment" *ngIf="message.attachments">
        <div
          class="message__attachment__item"
          *ngFor="
            let attachment of message.attachments;
            let i = index;
            trackBy: attachmentTracker
          "
        >
          <ng-container *ngIf="attachment.url">
            <a
              class="image"
              [href]="attachment.url"
              target="_blank"
              *ngIf="attachment.type == AttachmentType.image"
            >
              <img
                *ngIf="attachment.url"
                [src]="attachment.url"
                alt="{{ attachment.name || 'image' }}"
              />
            </a>
            <a
              [href]="attachment.url"
              target="_blank"
              *ngIf="attachment.type !== AttachmentType.image"
            >
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.file"
                >insert_drive_file</i
              >
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.video"
                >theaters</i
              >
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.audio"
                >mic</i
              >
            </a>
          </ng-container>

          <ng-container
            *ngIf="!attachment.url && attachment.mimetype && attachment.data"
          >
            <a
              [href]="toFile(attachment)"
              [download]="attachment.name || message.messageId + '_' + i"
            >
              <img
                *ngIf="attachment.type == AttachmentType.image"
                [src]="toFile(attachment)"
                alt="{{ attachment.name || 'image' }}"
              />
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.file"
                >insert_drive_file</i
              >
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.video"
                >theaters</i
              >
              <i
                class="material-icons"
                *ngIf="attachment.type == AttachmentType.audio"
                >mic</i
              >
            </a>
          </ng-container>

          <ng-container *ngIf="!attachment.url && !attachment.data">
            <i
              class="material-icons"
              *ngIf="attachment.type == AttachmentType.file"
              >insert_photo</i
            >
            <i
              class="material-icons"
              *ngIf="attachment.type == AttachmentType.file"
              >insert_drive_file</i
            >
            <i
              class="material-icons"
              *ngIf="attachment.type == AttachmentType.video"
              >theaters</i
            >
            <i
              class="material-icons"
              *ngIf="attachment.type == AttachmentType.audio"
              >mic</i
            >
          </ng-container>

          <div class="message__timestamp">
            <div class="info">
              {{ attachment.name }}
            </div>
            <div class="time">
              <ng-container *ngIf="timeString">{{ timeString }}</ng-container>
              <ng-container *ngIf="message.senderId === customerId">
                <i class="material-icons" *ngIf="!message.delivered">done</i>
                <i class="material-icons" *ngIf="message.delivered">done_all</i>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- Attachments -->
    </div>
  </div>

  <div
    class="quick-replies"
    *ngIf="
      message.senderId === FRIDAY_ID &&
      last &&
      message.quickReplies &&
      message.text &&
      message.text !== ''
    "
  >
    <ng-container *ngFor="let reply of message.quickReplies">
      <button
        class="quick-reply"
        (click)="quickReply(reply.title)"
        *ngIf="reply.type === QuickReplyType.Text && reply.title"
      >
        {{ reply.title }}
      </button>
    </ng-container>
  </div>
</ng-container>
