import { environment as defaultEnvironment } from "./environment.default";
export const environment = {
  ...defaultEnvironment,
  ENV: "PROD",
  apiEndpoint: "https://api.getfriday.ai",
  firebase: {
    apiKey: "AIzaSyAINiPXlajv3HW2SLxW0MxcU0y4B_dkuuQ",
    authDomain: "app.getfriday.ai",
    databaseURL: "https://friday-ai-production.firebaseio.com",
    projectId: "friday-ai-production",
    storageBucket: "friday-ai-production.appspot.com",
    messagingSenderId: "104814989886",
    appId: "1:104814989886:web:809df3478474c4f27ef76f",
    measurementId: "G-Z8QEGXPQ4C",
  },
  FRIDAY_API_TOKEN: "09mYjl2Zh12b0VXY",
};
