import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LivechatSetting } from "@models/livechat";
import { Conversation, ConversationParams, Message } from "@models/messenger";
import { SettingService } from "@shared";
import { clone, to, toConversationId } from "@utility";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { BehaviorSubject } from "rxjs";
import * as uuid from "uuid/v1";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ConversationService {
  private _db: firebase.firestore.Firestore;
  private _collection: firebase.firestore.CollectionReference;
  private _endpoint = environment.apiEndpoint;

  conversationUnsub: any;
  conversationLoaded = false;
  private _conversation$: BehaviorSubject<Conversation> = new BehaviorSubject(
    null
  );
  public conversationChanges = this._conversation$.asObservable();
  conversation: Conversation;

  constructor(
    private http: HttpClient,
    private settingService: SettingService
  ) {
    this._db = firebase.firestore();
    this._collection = this._db.collection(environment.CONVERSATION_COLLECTION);
  }

  async getLivechatSetting(token: string): Promise<LivechatSetting> {
    const collection = firebase
      .firestore()
      .collection(environment.LIVECHAT_COLLECTION);
    const [err, snapshots] = await to(
      collection.where("token", "==", token).get()
    );
    if (err || snapshots.empty) return null;
    return new LivechatSetting(snapshots.docs[0].data() as any);
  }

  listenConversation(clientId: string) {
    if (this.conversationUnsub) this.conversationUnsub();
    let customerId = this.settingService.getValue(clientId);
    if (!customerId) {
      customerId = uuid();
      this.settingService.updateValue(clientId, customerId);
    }

    // alert('listenCon:conversationId:' + conversationId);
    const conversationId = toConversationId(clientId, customerId);
    this.conversationUnsub = this._collection.doc(conversationId).onSnapshot(
      async (doc) => {
        let conversation: Conversation;
        if (doc.exists) {
          conversation = new Conversation(doc.data() as ConversationParams);
          conversation.messages = conversation.messages.filter(
            (m) => !m.pending && (m.text || m.attachments)
          );
        } else {
          conversation = new Conversation({
            clientId,
            conversationId,
            me: clientId,
          });
        }
        this.conversation = conversation;
        this._conversation$.next(this.conversation);
      },
      (error) => {
        console.error(error);
        // alert(error);
      }
    );
  }

  async sendMessage(clientId: string, message: Message) {
    if (!this.conversation) return false;
    const [err, sent] = await to(
      this.http
        .post(
          this._endpoint + "/message/reply",
          {
            clientId,
            conversationId: this.conversation.conversationId,
            message,
          },
          {
            headers: new HttpHeaders().set(
              "authorization",
              `Bearer ${environment.FRIDAY_API_TOKEN}`
            ),
          }
        )
        .toPromise()
    );
    if (err) {
      console.error(err);
      return false;
    }
    return true;
  }

  async createConversation(conversation: Conversation) {
    if (!conversation) return false;
    const [err, sent] = await to(
      this.http
        .post(
          this._endpoint + "/message/conversation/create",
          {
            conversation: clone(conversation),
          },
          {
            headers: new HttpHeaders().set(
              "authorization",
              `Bearer ${environment.FRIDAY_API_TOKEN}`
            ),
          }
        )
        .toPromise()
    );
    if (err) {
      console.error(err);
      return false;
    }
    return true;
  }
}
