export enum ResponseType {
  Text = "text",
  // Workflow = "workflow",
  Chatform = "chatform",
}

export enum AttachmentType {
  audio = "audio",
  video = "video",
  image = "image",
  file = "file",
  fallback = "fallback",
}

export enum ResponseSource {
  WIT = "wit",
  KB = "kb",
  Taskforce = "taskforce",
}

export interface Attachment {
  id: string;
  type: AttachmentType;
  url?: string;
  name?: string;
  location?: string;
  fbAttachmentId?: string;
  data?: string;
  mimetype?: string;
  size?: number;
}

export interface Response {
  type: ResponseType;
  text?: string;
  attachments?: Attachment[];
  chatformId?: string;
  // workflowId?: string;
}
