const uuid = require("uuid/v1");

export enum RunnerTaskName {
  callWitApi = "call_wit_api",
  dummy = "dummy",
}

export interface RunnerTaskParams {
  id?: string;
  name: RunnerTaskName;
  params: any;
  _createdAt?: number;
}

export class RunnerTask {
  id: string;
  name: RunnerTaskName;
  params: any;
  _createdAt: number;

  constructor(params: RunnerTaskParams) {
    this.id = params.id || uuid();
    this.name = params.name;
    this.params = params.params;
    this._createdAt = params._createdAt || new Date().valueOf();
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}
