import { reverse, containZH, containEN } from "@models/helper";
export * from "@models/helper";

export const isEN = (text: string): boolean => {
	if (!containEN(text)) return false;
	if (containZH(text)) return false;
	return true;
};

export const scrollToTop = (element: HTMLElement, delay = 200) => {
	if (!element) return;
	if (!delay) return (element.scrollTop = 0);
	setTimeout(_ => {
		element.scrollTop = 0;
	}, delay);
};

export const scrollToBottom = (element: HTMLElement, delay = 0) => {
	if (!element) return;
	if (!delay)
		return (element.scrollTop = element.scrollHeight - element.offsetHeight);
	setTimeout(_ => {
		element.scrollTop = element.scrollHeight - element.offsetHeight;
	}, delay);
};

export const addClass = (id: string, className: string) => {
	const element = document.getElementById(id);
	if (!element) return;
	return element.classList.add(className);
};

export const removeClass = (id: string, className: string) => {
	const element = document.getElementById(id);
	if (!element) return;
	return element.classList.remove(className);
};

export const hash = (text: string) => {
	return window.btoa(reverse(text));
};

export const unhash = (text: string) => {
	return reverse(window.atob(text));
};
