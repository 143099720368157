import { Component, OnInit } from "@angular/core";
import { SettingService } from "@shared";
import * as firebase from "firebase/app";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private settingService: SettingService) {}

  ngOnInit() {
    firebase.initializeApp(environment.firebase);
    this.settingService.initialize();
  }
}
