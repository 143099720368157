import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LivechatLocale, LivechatSetting } from "@models/livechat";
import { Attachment, Channel, Conversation, Message } from "@models/messenger";
import {
  Language,
  REGEX_EMAIL,
  REGEX_PHONE,
  SupportedLanguages,
} from "@models/misc";
import { TranslateService } from "@ngx-translate/core";
import { animations, FirestoreService, SettingService } from "@shared";
import {
  customerIdFromConversationId,
  mask,
  scrollToBottom,
  to,
} from "@utility";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { ConversationService } from "../../services/conversation.service";
const PRODUCTION = environment.ENV === "PROD";

@Component({
  selector: "app-chatroom",
  templateUrl: "./chatroom.component.html",
  styleUrls: ["./chatroom.component.scss"],
  animations: animations,
})
export class ChatroomComponent implements OnInit, OnDestroy {
  @ViewChild("chatroom") chatroomElement: ElementRef;
  @ViewChild("upload") uploadElement: ElementRef;
  conversation: Conversation;
  customerId: string;
  messageText = "";
  messageAttachments: Attachment[] = [];
  uploads: File[] = [];
  initialized = false;
  sending = false;
  uploading = false;

  loadingNewMessage = false;
  conversationSub: Subscription;
  clientId: string;
  token: string;
  origin: string;
  private _originURL$: BehaviorSubject<string> = new BehaviorSubject(null);
  originURLChange = this._originURL$.asObservable();
  livechatConfig: LivechatSetting;
  language: Language;
  locale: LivechatLocale;
  languages: Language[] = SupportedLanguages;
  contact: string = "";
  contactError = false;

  constructor(
    public conversationService: ConversationService,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private firestoreService: FirestoreService
  ) {
    this.token = this.route.snapshot.params.token;
    this.origin = this.route.snapshot.queryParams.origin;

    window.addEventListener(
      "message",
      (event) => {
        console.log(event.origin, event.data);
        if (this.origin.indexOf(event.origin) !== 0) return false;
        const data = JSON.parse(event.data);
        this.messageHandler(data);
      },
      false
    );
  }

  async ngOnInit() {
    if (!this.token || !this.origin) return false;
    console.log("chatroom init", this.origin, this.token);
    this.language = this.settingService.settings.language;
    this.livechatConfig = await this.conversationService.getLivechatSetting(
      this.token
    );

    if (!this.livechatConfig || !this.livechatConfig.clientId) return false;
    this.clientId = this.livechatConfig.clientId;

    if (this.livechatConfig.themeColor)
      document.body.style.setProperty(
        "--themeColor",
        this.livechatConfig.themeColor
      );
    if (
      this.livechatConfig.locales &&
      this.livechatConfig.locales[this.language]
    )
      this.locale = this.livechatConfig.locales[this.language];

    if (!environment.debug) {
      console.log(this.livechatConfig, this.origin);
      this.postToOrigin(`friday-theme-color:${this.livechatConfig.themeColor}`);
      this.postToOrigin("friday-chatroom-loaded");
    } else {
      this.checkURL(this.origin);
      this.conversationService.listenConversation(this.clientId);
    }

    this.originURLChange.subscribe((url) => {
      console.log("Origin url changed:", url);
      if (!url) return false;
      if (this.checkURL(url)) {
        this.conversationService.listenConversation(this.clientId);
        this.postToOrigin("friday-show-toggle");
      } else {
        this.conversation = null;
        this.postToOrigin("friday-hide-toggle");
      }
    });

    this.translateService.onLangChange.subscribe((change) => {
      if (
        this.livechatConfig.locales &&
        this.livechatConfig.locales[change.lang]
      )
        this.locale = this.livechatConfig.locales[change.lang];
    });

    this.conversationSub = this.conversationService.conversationChanges.subscribe(
      (conversation) => {
        if (conversation) {
          if (!this.conversation) {
            this.conversation = conversation;
          } else {
            conversation.messages.forEach((m, i) => {
              if (i < this.conversation.messages.length) {
                if (
                  m.messageId === this.conversation.messages[i].messageId &&
                  (this.conversation.messages[i].timestamp !== m.timestamp ||
                    this.conversation.messages[i].delivered !== m.delivered)
                ) {
                  this.conversation.messages[i].timestamp = m.timestamp;
                  this.conversation.messages[i].delivered = m.delivered;
                  // console.log(clone(this.conversation.messages[i]));
                }
              } else {
                this.conversation.messages.push(m);
              }
            });
          }

          if (
            this.conversation.messages.length > 0 &&
            conversation.messages.length > this.conversation.messages.length
          ) {
            const customerId = customerIdFromConversationId(
              conversation.conversationId
            );
            const lastMessage =
              conversation.messages[conversation.messages.length - 1];
            if (lastMessage.senderId !== customerId && !lastMessage.pending) {
              this.postToOrigin("friday-show-unread");
            }
          }

          console.log(this.conversation);

          setTimeout((_) => {
            if (this.chatroomElement)
              scrollToBottom(this.chatroomElement.nativeElement);
          }, 1000);
        }
      }
    );
  }

  ngOnDestroy() {
    this.discardUploads();
    if (this.conversationSub) this.conversationSub.unsubscribe();
  }

  messageTracker(index: number, message: Message) {
    return message.text
      ? `${index.toString()}-${message.text}`
      : `${index.toString()}-${message.timestamp}`;
  }

  reset() {
    this.messageText = "";
    this.uploadElement.nativeElement.value = "";
    this.messageAttachments = [];
    this.uploads = [];
    this.sending = false;
    this.uploading = false;
  }

  async discardUploads() {
    if (this.messageAttachments.length > 0) {
      console.log(`Deleting ${this.messageAttachments.length} attachments`);
      await to(
        this.firestoreService.clientBatchDelete(
          this.clientId,
          this.messageAttachments
        )
      );
      this.messageAttachments = [];
    }
    return true;
  }

  quickReply(text: string) {
    this.messageText = text;
    return this.sendMessage();
  }

  messageHandler(data: any) {
    console.log(data);
    switch (data.id) {
      case "friday-url-change":
        this._originURL$.next(data.url);
        break;
      case "friday-chatroom-open":
        setTimeout((_) => {
          if (this.chatroomElement)
            scrollToBottom(this.chatroomElement.nativeElement);
        }, 1000);
        break;
    }
  }

  postToOrigin(data: any) {
    if (environment.debug) return;
    if (this.origin) {
      // console.log(this.origin);
      try {
        window.parent.postMessage(data, this.origin);
      } catch (e) {
        console.log(e);
      }
    }
  }

  closeChatroom() {
    this.discardUploads();
    this.postToOrigin("friday-close-chatroom");
  }

  cleanURL(url: string) {
    url = url
      .replace(/http(s)?:\/\//i, "")
      .replace("www.", "")
      .trim();
    const length = url.length;
    if (url[length - 1] === "/") url = url.substring(0, length - 1);
    return url;
  }

  checkURL(url: string) {
    if (!this.livechatConfig) return false;
    const urls = this.livechatConfig.urls;
    if (!urls || urls.length === 0) return false;
    url = this.cleanURL(url);
    console.log(urls, url);
    let i = 0;
    let allow = false;
    while (i < urls.length && !allow) {
      const n = this.cleanURL(urls[i]).indexOf(url);
      console.log(n);
      if (n === 0) allow = true;
      i++;
    }
    console.log(allow);
    return allow;
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
    // console.log(this.settingService.settings);
  }

  async sendMessage() {
    if (!this.messageText && this.uploads.length === 0) return;
    this.sending = true;

    const text = this.messageText;
    this.messageText = "";

    if (this.uploads.length > 0)
      this.messageAttachments = await this.firestoreService.clientBatchUpload(
        this.clientId,
        this.uploads,
        PRODUCTION,
        `/c/${mask(
          customerIdFromConversationId(this.conversation.conversationId)
        )}`,
        true,
        true
      );
    console.log(this.messageAttachments);

    const message = new Message({
      senderId: this.conversation.customer.customerId,
      text: this.messageText,
      attachments: this.messageAttachments,
      channel: Channel.Web,
    });

    const succeed = await this.conversationService.sendMessage(
      this.clientId,
      message
    );
    if (!succeed) await this.discardUploads();
    this.reset();
  }

  uploadFile() {
    this.uploadElement.nativeElement.click();
  }

  async onFileChange(event: any) {
    console.log(event.target.files, this.uploads);
    this.uploading = true;
    if (event.target.files && event.target.files.length > 0) {
      const files: File[] = event.target.files;
      this.uploads = [...this.uploads, ...files];
      this.uploadElement.nativeElement.value = "";
      console.log(this.uploads);
    }
    this.uploading = false;
  }

  async deleteFile(i: number) {
    this.uploads.splice(i, 1);
    console.log(this.uploads);
  }

  insertEmoji(event: any) {
    this.messageText += event.emoji.native || "";
    event.$event.stopPropagation();
  }

  updateContact() {
    this.contact = this.contact.trim();
    // console.log(this.contact, this.conversation.customer);
    const isEmail = !!this.contact.match(REGEX_EMAIL);
    const isPhone = !!this.contact.match(REGEX_PHONE);
    this.contactError = !(isEmail || isPhone);
    if (!this.contactError) {
      isEmail
        ? (this.conversation.customer.email = this.contact)
        : (this.conversation.customer.phoneNumber = this.contact);
      // console.log(this.conversation.customer);
      this.conversationService.createConversation(this.conversation);
    }
  }
}
